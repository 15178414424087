import * as API from '@/api/index'

 export default {
     // 支出类别列表
     all: params => {
         return API.POST('api/additionalSpendingCategory/all', params)
     },
     //新增类别
     create: params => {
         return API.POST('api/additionalSpendingCategory/create', params)
     },
     //编辑类别
     update:params => {
         return API.POST('api/additionalSpendingCategory/update', params)
     },
     //类别顺序调整
     move:params => {
         return API.POST('api/additionalSpendingCategory/move', params)
     },
 
 }